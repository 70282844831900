









import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private centers: any;
  private jump(center: any) {
    this.$emit("jump", center);
  }
}
