





















import { Component, Vue, Prop } from "vue-property-decorator";
import { GetCenter } from "@/request/common";
import MainHeader from "@/components/main-header/main-header.vue";
import NormalSelect from "../components/storehouse-select/normal-select.vue";
import DemoSelect from "../components/storehouse-select/demo-select.vue";
@Component({
  components: {
    MainHeader,
    NormalSelect,
    DemoSelect,
  },
})
export default class Name extends Vue {
  private centers: any[] = [];
  private get selectType() {
    return this.$store.state.configuration.selectType;
  }
  /**
   * @description 获得中心列表
   */
  private fetchCenters() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetCenter(this, loading)
      .then((data: any) => {
        loading.close();
        this.centers = data;
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 跳转
   */
  private jump(center: any) {
    this.$store.commit("updateCenter", center);
    this.$router.push({
      path: "/main/storehouse/case/list",
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.fetchCenters();
  }
}
